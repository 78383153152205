@import "../mixins.scss";

.slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.slider-slides-container {
  width: 964px;
  height: 430px;
  padding-top: 10px;
  margin: 0 auto;
  overflow: hidden;
}
.slider-slides {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}
.slider-arrow {
  position: absolute;
  top: calc(50% - 16px);
  left: 0;
  width: 32px;
  height: 32px;
  background-image: url("#{$VUE_APP_BASE}/assets/icons/article-slider-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  cursor: pointer;
}
.slider-arrow-next {
  transform: rotate(0deg);
  left: unset;
  right: 0;
}

@media screen and (max-width: 1110px) {
  .slider-slides-container {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-arrow {
    display: none;
  }
}
@include tablet-wide {
  .slider-slides-container {
    min-width: calc(100% + 80px);
    padding-left: 40px;
    position: relative;
    left: -40px;
  }
}
@include mobile {
  .slider-slides-container {
    min-width: calc(100% + 40px);
    padding-left: 20px;
    position: relative;
    left: -20px;
  }
}
@import "../colors.scss";
@import "../mixins.scss";

.investments {
  padding-top: 100px;
  background: $white-text;
  .investments-content {
    position: relative;
    z-index: 1;
    max-width: 1280px;
    margin: auto;
    padding: 0 40px;
    padding-bottom: 6rem;
  }

  .block-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2.5rem;
  }
  .block-item {
    opacity: 0;
    position: relative;
    text-align: center;
    max-width: 270px;

    .block-item-blur {
      position: absolute;
      border-radius: 16px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(3px);
      filter: blur(3px);
    }

    .block-item-content {
      position: relative;
      z-index: 1;
    }

    h3 {
      width: auto;
      margin: auto;
      background: $dark-text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 1.25rem;
    }
    ul {
      padding: 0;
      margin-bottom: 0;
      li {
        font-family: MTSSansRegular;
        color: $dark-text;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0.75rem;
        text-align: left;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:first-child,
    &:nth-child(2),
    &:last-child {
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  &.animated {
    .block-item {
      &:first-child {
        animation-name: investment1;
      }

      &:nth-child(2) {
        animation-name: investment2;
      }

      &:last-child {
        animation-name: investment3;
      }
    }
  }

  .block-footer {
    p {
      text-align: center;
      font-size: 12px;
      line-height: 17px;
      color: $gray-text;
    }
  }
  .block-footer-mobile {
    display: none;
  }

  @include below-desktop {
    //margin-top: -2rem;
    .investments-content {
      padding: 5rem 40px;
      padding-top: 0;
    }
  }

  @include intermediate {
    .block-content {
      .block-item {
        max-width: 230px;
        padding: 1rem;
        ul {
          padding-left: 1rem;
        }
      }
    }
  }

  @include tablet {
    .block-content {
      //flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 1rem;
      .block-item {
        max-width: 45%;
        width: 45%;
        padding: 1rem;
        margin-bottom: 2rem;
        ul {
          padding-left: 1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include mobile {
    margin: 0;
    padding-top: 80px;
    .investments-content {
      padding: 0 20px;
      padding-bottom: 98px;
      .block-header {
        margin-bottom: 2.5rem;
      }
    }

    .block-content {
      display: block;
      .block-item {
        padding: 0;
        margin-right: 0;
        width: 90%;
        max-width: 90%;
        margin-bottom: 48px;

        h3 {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .block-footer-desktop {
      display: none;
    }
    .block-footer-mobile {
      display: block;
      margin-top: 8px;
      p {
        text-align: left;
      }
    }
  }
}

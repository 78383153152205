@import "../colors.scss";
@import "../mixins.scss";

.article {
  width: 300px;
  min-width: 300px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px #00000014, 0px 0px 16px 0px #00000014;
  &:hover {
    .article-info {
      bottom: 0;
    }
    .article-text {
      display: none;
    }
    .article-text-full {
      display: block;
    }
  }
}
.article-img {
  position: relative;
  width: 300px;
  height: 407px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $dark-white-background;
  border-radius: 16px;
}
.article-img-icon {
  background-size: 224px 224px;
  background-position: center top 50px;
}
.article-info {
  position: absolute;
  bottom: -37px;
  padding: 16px;
  background: white;
  border-radius: 16px;
  transition-duration: 0.4s;
}
.article-link {
  color: $dark-blue-text;
}
.article-text-full {
  display: none;
}
.article-date, .article-text, .article-text-full {
  @include prime-text-small;
  margin-bottom: 12px;
}
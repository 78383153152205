@import "../colors.scss";
@import "../mixins.scss";

.services-background {

}
.services {
  width: 1280px;
  padding: 100px 40px;
  margin: 0 auto;
  //background: linear-gradient(76.8deg, #000000 16.14%, #0074D1 79.65%);
  background-image: url('#{$VUE_APP_BASE}/assets/images/landing/backgrounds/gradient.svg');
  background-size: cover;
  border-radius: 16px;
}
.services-title {
  margin-bottom: 56px;
  font-family: MTSWideMedium;
  font-size: 32px;
  color: $light-white-text;
}
.services-scrollable {
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.services-container {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 32px;
}
@include below-desktop {
  .services {
    width: 100%;
  }
}
@include tablet-wide {
  .services-scrollable {
    width: calc(100% + 80px);
    position: relative;
    left: -40px;
    padding-left: 40px;
  }
}
@include mobile {
  .services {
    width: unset;
    padding: 100px 20px;
  }
  .services-title {
    font-size: 20px;
  }
  .services-container {
    width: 100%;
    min-width: 100%;
    flex-direction: column;
  }
  .services-scrollable {
    position: static;
    padding-left: unset;
    width: 100%;
  }
}
@import "../colors.scss";
@import "../mixins.scss";

.articles {
  padding: 40px 0;
  background: white;
}
.articles-container {
  width: 1280px;
  padding: 60px 40px;
  margin: 0 auto;
}
.articles-title {
  margin-bottom: 32px;
  font-family: MTSWideMedium;
  font-size: 32px;
  color: $dark-text;
}
.articles-slide {
  border: none !important;
  background-color: unset !important;
  box-shadow: none !important;
}

@include below-desktop {
  .articles-container {
    width: 100%;
  }
}

@include mobile {
  .articles-container {
    padding: 60px 20px;
  }
  .articles-title {
    font-size: 20px;
  }
}
@import "../colors.scss";
@import "../mixins.scss";

.faq {
  background: white;
  .faq-container {
    width: 1280px;
    margin: 0 auto;
    padding: 40px 40px 70px;
  }
  .faq-title {
    margin-bottom: 32px;
    font-family: MTSWideMedium;
    font-size: 32px;
    color: $dark-text;
  }
  .faq-questions-container {
    padding: 12px 32px;
    border-radius: 16px;
    background: $dark-white-background;
  }
}

@include below-desktop {
  .faq .faq-container {
    width: 100%;
  }
}

@include mobile {
  .faq .faq-container {
    padding: 20px 20px 70px;
  }
  .faq .faq-title {
    font-size: 20px;
  }
  .faq .faq-questions-container {
    padding: 12px 20px;
  }
}
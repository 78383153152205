@import "../colors.scss";
@import "../mixins.scss";

.how-it-works {
  width: 100%;
  max-width: 1280px;
  padding: 80px 40px;
  padding-bottom: 100px;
  margin: 0 auto;
  border-radius: 16px;
  background: $dark-white-background;
}
.how-it-works-title {
  margin-bottom: 32px;
  font-family: MTSWideMedium;
  font-size: 32px;
  color: $dark-text;
}
.how-it-works-description {
  max-width: 627px;
  margin-bottom: 32px;
  @include prime-text-normal;
}
.how-it-works-blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.how-it-works-block {
  width: 368px;
  min-width: 352px;
}
.how-it-works-block-header {
  position: relative;
  padding: 20px;
  min-height: 205px;
  background: white;
  border-radius: 16px;
  z-index: 1;
}
.how-it-works-block-title {
  margin-bottom: 24px;
  font-family: MTSWideMedium;
  font-size: 24px;
  color: $dark-text;
}
.how-it-works-block-text {
  @include prime-text-small;
}
.how-it-works-block-img {
  height: 168px;
  position: relative;
  top: -25px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;

  // скрыто до покупки изображений
  display: none;
}
.how-it-works-block-img-1 {
  //background-image: url("#{$VUE_APP_BASE}/assets/images/landing/how-it-works/1.jpg");
  background-color: gray;
}
.how-it-works-block-img-2 {
  //background-image: url("#{$VUE_APP_BASE}/assets/images/landing/how-it-works/2.jpg");
  background-color: gray;
}
.how-it-works-block-img-3 {
  //background-image: url("#{$VUE_APP_BASE}/assets/images/landing/how-it-works/3.jpg");
  background-color: gray;
}

.how-it-works-button-container {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.how-it-works-button {
  width: 268px;
  transition-duration: 0.4s;
  border: none;
  outline: none;
  background: none;
  .buttonDH {
    height: 44px;
    padding: 10px;
    background: $button-red;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: $button-red;
    }
  }
  .buttonContentDH {
    @include medium-text-normal-uncolored;
    text-align: center;
    color: $light-white-text;
  }
  &:hover {
    transform: scale(1.02);
  }
}

@include tablet-wide {
  .how-it-works-blocks {
    width: calc(100% + 80px);
    position: relative;
    left: -40px;
    padding-left: 40px;
  }
  .how-it-works-button {
    .buttonDH {
      height: 32px;
      padding: 4px;
    }
    .buttonContentDH {
      font-size: 14px;
    }
  }
}

@include mobile {
  .how-it-works {
    padding: 80px 20px;
  }
  .how-it-works-title {
    font-size: 20px;
  }
  .how-it-works-description {
    font-size: 14px;
  }
  .how-it-works-blocks {
    flex-direction: column;
    width: calc(100% + 40px);
  }
  .how-it-works-block {
    width: 100%;
    min-width: unset;
  }
  .how-it-works-block-title {
    font-size: 18px;
  }
  .how-it-works-button {
    width: 100%;
  }
}
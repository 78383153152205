@import "../colors.scss";
@import "../mixins.scss";

.question {
  .block-wrap {
    width: 1280px;
    padding: 2rem 40px;
    //background-color: $dark-text;
    //background-repeat: no-repeat;
    //background-size: cover;
    //background-position: 0 100%;
    background-image: url('#{$VUE_APP_BASE}/assets/images/landing/backgrounds/gradient.svg');
    background-size: cover;
    border-radius: 16px;
  }
  .block-content {
    h3 {
      color: $white-text;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 1rem;
    }
    p {
      max-width: 510px;
      color: $white-text;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 1.25rem;
      margin-right: 11.25rem;
    }

    .btn,
    .btn-text {
      max-width: 335px;
      padding: 0;
      background: none;
      width: 100%;
      .buttonDH {
        display: block;
        color: $white-text;
        transition: 0.3s;
        width: 100%;
        height: auto;
        padding: 0.5rem;
        background: $dark-text;
        border-radius: 6px;
        .buttonContentDH {
          font-family: MTSSansMedium;
          color: $white-text;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &:hover {
        .buttonDH {
          color: $white-text;
          transform: scale(1.02);
        }
      }

      button:not(#ZVK_accordion-open) {
        transition: 0.3s;
        height: auto;
        padding: 0.5rem;
        background: $dark-text;
        border-radius: 6px;
        div {
          font-family: MTSSansMedium;
          color: $white-text;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    .block-content {
      p {
        margin-right: 3rem;
      }
    }
  }

  @include intermediate {
    .block-content {
      p {
        margin-right: 2rem;
      }
    }
  }
  @include mobile {
    .block-content {
      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .block-content-body {
      flex-direction: column;
      p {
        max-width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        margin-bottom: 2rem;
      }
      .btn {
        margin: auto;
      }
    }
  }
}

.question-btn .btn {
  background: white;
  width: 268px;
  margin-right: 148px;
  transition-duration: 0.4s;
  border: none;
  outline: none;
  background: none;
  .buttonDH {
    padding: 10px 109px !important;
    background: $button-red !important;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: $button-red !important;
    }
  }
  .buttonContentDH {
    text-align: center;
    font-family: MTSCompactMedium;
    font-size: 17px !important;
    color: $white-text !important;
  }
  &:hover {
    transform: scale(1.02);
  }
}
@include below-desktop {
  .question .block-wrap {
    width: 100%;
    padding: 2rem 40px;
  }
}
@include tablet-wide {
  .question .block-wrap {
    width: 100%;
    padding: 70px 40px 63px;
  }
  .question-btn {
    min-width: 268px;
  }
  .question-btn .btn {
    .buttonDH {
      width: 100%;
      padding: 4px !important;
    }
    .buttonContentDH {
      font-size: 14px !important;
    }
  }
}
@include mobile {
  .question .block-content-body .btn {
    max-width: 100%;
    width: 100%;
  }
  .question  .block-wrap {
    padding: 2rem 20px;
  }
}
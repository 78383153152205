@import "../colors.scss";
@import "../mixins.scss";

.industries {
  width: 1280px;
  margin: 0 auto;
  padding-top: 100px;
  border-radius: 16px;
  background: $dark-white-background;
}
.industries-container {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 40px;
  padding-bottom: 6rem;
}
.industries-title {
  margin-bottom: 32px;
  font-family: MTSWideMedium;
  font-size: 32px;
  color: $dark-text;
}
.industries-text {
  width: 537px;
  margin-bottom: 32px;
  font-family: MTSSansRegular;
  font-size: 17px;
  color: $dark-text;
}
.industries-blocks-container {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.industries-blocks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
}
.industries-block {
  width: 100%;
  padding: 20px;
  min-height: 155px;
  background: white;
  border-radius: 16px;
}
.industries-block-title {
  margin-bottom: 16px;
  font-family: MTSWideMedium;
  font-size: 24px;
  color: $dark-text;
}
.industries-block-text {
  font-family: MTSSansRegular;
  font-size: 14px;
  color: $dark-text;
}
.industries-button-container {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.industries-button {
  width: 268px;
  padding: 10px;
  transition-duration: 0.4s;
  border: none;
  outline: none;
  background: none;
  .buttonDH {
    height: 44px;
    padding: 10px;
    background: $button-red;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: $button-red;
    }
  }
  .buttonContentDH {
    @include medium-text-normal-uncolored;
    text-align: center;
    color: $light-white-text;
  }
  &:hover {
    transform: scale(1.02);
  }
}

@include below-desktop {
  .industries {
    width: 100%;
  }
}
@media screen and (max-width: 925px) {
  .industries-blocks {
    min-width: 937px;
  }
}
@include tablet-wide {
  .industries-blocks-container {
    width: calc(100% + 80px);
    padding-left: 40px;
    position: relative;
    left: -40px;
  }
  .industries-button {
    width: 268px;
    padding: 10px 0;
    transition-duration: 0.4s;
    .buttonDH {
      height: 32px;
      padding: 4px;
    }
    .buttonContentDH {
      font-size: 14px;
    }
  }
}
@include mobile {
  .industries-container {
    padding: 0 20px;
  }
  .industries-blocks-container {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    padding-left: unset;
  }
  .industries-button-container {
    margin-bottom: 52px;
  }
  .industries-button {
    width: 100%;
  }
  .industries-blocks {
    padding-left: 20px;
  }
  .industries-title {
    font-size: 20px;
  }
  .industries-text {
    font-size: 14px;
    width: unset;
  }
  .industries-block-title {
    font-size: 18px;
  }
}
@import "../mixins.scss";
@import "../colors.scss";

.mobile-block-window {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 2;
}
.mobile-block-window-title-container {
  width: 100%;
  max-width: 320px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.mobile-block-window-title {
  font-family: MTSExtendedBlack;
  text-transform: uppercase;
  font-size: 20px;
  color: $dark-text;
}
.mobile-block-window-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-block-window-info-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  background-image: url('#{$VUE_APP_BASE}/assets/images/errors/mobile-block.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.mobile-block-window-info-text {
  @include prime-text-small-uncolored;
  text-align: center;
  color: $gray-text;
}
.mobile-block-window-button {
  @include medium-text-small-uncolored;
  width: 100%;
  padding: 6px;
  border-radius: 6px;
  color: $dark-text;
  background: $dark-white-background;
  border: none;
  outline: none;
  cursor: pointer;
}

@import "../colors.scss";
@import "../mixins.scss";

.just-try {
  width: 1280px;
  position: relative;
  margin: 0 auto;
  padding: 90px 40px;
  border-radius: 16px;
  //background: linear-gradient(76.8deg, #000000 16.14%, #0074D1 79.65%);
  background-image: url('#{$VUE_APP_BASE}/assets/images/landing/backgrounds/gradient.svg');
  background-size: cover;
}
.just-try-text {
  margin-bottom: 32px;
}
.just-try-title {
  font-family: MTSWideMedium;
  font-size: 32px;
  color: $light-white-text;
  white-space: pre;
}
.just-try-subtitle {
  margin-top: 16px;
  font-family: MTSWideRegular;
  font-size: 20px;
  color: $light-white-text;
}
.just-try-note {
  position: absolute;
  right: 104px;
  top: calc(50% - 386px / 2);
  width: 386px;
  height: 386px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.just-try-note-1 {
  background-image: url("#{$VUE_APP_BASE}/assets/images/landing/notes/note1.webp");
}
.just-try-note-2 {
  background-image: url("#{$VUE_APP_BASE}/assets/images/landing/notes/note2.webp");
}
.just-try-note-3 {
  background-image: url("#{$VUE_APP_BASE}/assets/images/landing/notes/note3.webp");
}
.just-try-button {
  display: block;
  width: 268px;
  transition-duration: 0.4s;
  border: none;
  outline: none;
  background: none;
  .buttonDH {
    height: 44px;
    padding: 10px;
    background: $button-red;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: $button-red;
    }
  }
  .buttonContentDH {
    @include medium-text-normal-uncolored;
    text-align: center;
    color: $white-text;
  }
  &:hover {
    transform: scale(1.02);
  }
}

@include below-desktop {
  .just-try {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }
}
@include intermediate {
  .just-try-title {
    font-size: 24px;
  }
  .just-try-note {
    right: 10px;
    width: 354px;
    height: 354px;
  }
}
@include tablet-wide {
  .just-try-button {
    .buttonDH {
      height: 32px;
      padding: 4px;
    }
    .buttonContentDH {
      font-size: 14px;
    }
  }
}
@include tablet {
  .just-try-title {
    width: 65%;
    white-space: unset;
  }
}

@include mobile {
  .just-try {
    padding: 50px 20px;
  }
  .just-try-text {
    margin-bottom: 0;
  }
  .just-try-button {
    width: 100%;
  }
  .just-try-title {
    width: 100%;
    white-space: unset;
  }
  .just-try-note {
    position: static;
  }
}
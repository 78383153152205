@import "../colors.scss";
@import "../mixins.scss";

.faq-question-header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faq-question-header-text {
  font-family: MTSCompactMedium;
  font-size: 20px;
  color: $dark-text;
}
.faq-question-header-arrow {
  width: 22px;
  height: 11px;
  min-width: 22px;
  min-height: 11px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("#{$VUE_APP_BASE}/assets/icons/question-arrow.svg");
  &.opened {
    transform: rotate(180deg);
  }
}
.faq-question-body {
  @include prime-text-normal;
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
}
.faq-question-body-hidden {
  visibility: hidden;
  height: 0;
  padding: 0;
}

@include mobile {
  .faq-question-header-text {
    font-size: 14px;
  }
  .faq-question-header-arrow {
    width: 10px;
    height: 6px;
    min-width: 10px;
    min-height: 6px;
    margin-left: 12px;
  }
  .faq-question-body {
    font-size: 14px;
  }
}

@import "../colors.scss";
@import "../mixins.scss";

.ticker-page-block {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
.ticker-container {
  min-width: 3000px;
  width: 200%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticker {
  width: 104%;
}
.ticker-row {
  position: relative;
  padding-bottom: 16px;
  padding-right: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: marquee 20s linear infinite;
}
.ticker-row-elem {
  font-family: MTSWideMedium;
  font-size: 17px;
  color: $dark-blue-text;
}

@include below-desktop {
  .ticker-page-block {
    width: 100%;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}

@keyframes swap {
  0%, 50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}
@import "../colors.scss";
@import "../mixins.scss";

.cookies-warning-container {
  width: 100%;
  padding: 0 40px;
  position: fixed;
  bottom: 40px;
  z-index: 100;
}
.cookies-warning {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 1168px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px #00000014, 0px 0px 16px 0px #00000014;
}
.cookies-warning-text {
  @include prime-text-small;
  line-height: 20px;
  a {
    color: $link-blue-text;
  }
}
.cookies-warning-button {
  @include medium-text-small-uncolored;
  min-width: 160px;
  margin-left: 10px;
  padding: 6px;
  color: white;
  background: #FF0032;
  border: none;
  outline: none;
  border-radius: 6px;
  transition-duration: 0.15s;
  cursor: pointer;
  &:hover {
    transform: scale(1.04);
  }
}

@include mobile {
  .cookies-warning-container {
    padding: 0 20px;
    bottom: 20px;
  }
  .cookies-warning {
    flex-direction: column;
  }
  .cookies-warning-button {
    width: 100%;
    margin-top: 10px;
    min-width: unset;
  }
}
@import "../colors.scss";
@import "../mixins.scss";

.contacts-modal-layout {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(29, 32, 35, 0.4);
  z-index: 101;
  opacity: 0;
  transition-duration: 0.2s;
  pointer-events: none;
}
.contacts-modal-layout-opened {
  opacity: 1;
  pointer-events: auto;
}
.contacts-buttons-layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
}
.contacts-modal-layout-container, .contacts-buttons-layout-container {
  position: relative;
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
.contacts-phone-modal-container-anim {
  position: absolute;
  right: 40px;
  bottom: 122px;
  width: auto;
  max-width: 688px;
  z-index: 102;
  overflow: hidden;
  transition-duration: 0.4s;
  &.active {
    width: 100%;
  }
}
.contacts-phone-modal {
  //width: 0;
  position: relative;
  right: -100%;
  max-width: 688px;
  background: linear-gradient(76.8deg, #000000 16.14%, #0074D1 79.65%);
  border-radius: 16px;
  transition-duration: 0.2s;
  &.active {
    right: 0;
    width: 100%;
  }
}

.contacts-phone-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 688px;
  padding: 16px;
}

.contacts-phone-modal-text {
  @include prime-text-small-uncolored;
  width: 292px;
  margin-right: 32px;
  color: $light-white-text;
}
.contacts-phone-modal-input {
  @include prime-text-small-uncolored;
  margin-right: 32px;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  outline: none;
}
.contacts-phone-modal-button {
  padding: 6px 28px;
  font-family: MTSSansMedium;
  font-size: 14px;
  color: $light-white-text;
  white-space: nowrap;
  border-radius: 6px;
  border: none;
  outline: none;
  background: $dark-text;
  transition-duration: 0.15s;
  cursor: pointer;
  &:hover {
    transform: scale(1.04);
  }
  &:disabled {
    cursor: default;
    color: gray;
    &:hover {
      transform: none;
    }
  }
}
.contacts-buttons {
  position: absolute;
  right: 40px;
  bottom: 56px;
  z-index: 101;
  pointer-events: auto;
}
.contacts-button {
  margin-bottom: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: linear-gradient(76.8deg, #000000 16.14%, #0074D1 79.65%);
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px #00000014, 0px 0px 16px 0px #00000014;
}
.contacts-button-phone {
  width: 18px;
  height: 18px;
  background-image: url('#{$VUE_APP_BASE}/assets/icons/buttons/button-phone.svg');
}
.contacts-button-tg {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('#{$VUE_APP_BASE}/assets/icons/buttons/button-telegram.svg');
}

@include below-desktop {
  .contacts-modal-layout-container, .contacts-buttons-layout-container {
    width: 100%;
  }
  .contacts-phone-modal-container-anim.active {
    width: calc(100% - 80px);
  }
}

@include mobile {
  .contacts-phone-modal-container {
    flex-direction: column;
  }
  .contacts-phone-modal-text, .contacts-phone-modal-input {
    margin-right: 0;
    margin-bottom: 32px;
  }
  .contacts-phone-modal-text {
    width: 100%;
  }
  .contacts-phone-modal-input {
    width: 100%;
  }
  .contacts-phone-modal-button {
    width: 100%;
    color: $dark-text;
    text-align: center;
    background: white;
  }
}

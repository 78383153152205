@import "../colors.scss";
@import "../mixins.scss";

.service-block {
  position: relative;
  padding: 20px 10px;
  width: 100%;
  height: 200px;
  background-color: $white-text;
  background-size: 100%;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 16px;
  overflow: hidden;
  transition-duration: 0.4s;
  cursor: pointer;
  &.wide {
    width: 30%;
  }
  &:hover {
    background-size: 105%;
    .service-block-description {
      bottom: 0;
    }
  }
}
.service-block-title {
  position: absolute;
  top: 20px;
  left: 10px;
  font-family: MTSWideMedium;
  font-size: 20px;
  color: $dark-text;
}
.service-block-description {
  @include prime-text-small;
  position: absolute;
  bottom: -60%;
  left: 0;
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  transition-duration: 0.4s;
}

@include mobile {
  .service-block {
    width: 100%;
  }
  .service-block-description {
    bottom: 0;
  }
  .service-block.wide {
    width: 100%;
  }
}